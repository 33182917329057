@media (min-width: 992px) {
  .main-header-navigation {
    position: relative;
    top: -14px;
    margin-bottom: -30px;

    .nav-item { // generic level-0 item
      margin-right: 1rem;
      height: 62px;
      padding: 10px .5rem;

      &:hover, &.show {
        background: #fff;
      }

      &:last-child {
        margin-right: 0;
      }

      &.b-nav-dropdown { // dropdown specific stylings

        .dropdown-toggle {

          &:after {
            margin-left: .5rem;
          }
        }

        .dropdown-menu {
          min-width: 20rem;
          background: #fff;
          border: none;
          margin-left: -.5rem;
        }
      }
    }
  }
}