@import "custom";

$theme-colors: (
        "info": #d8e8ee,
        "danger": #ff4136
);

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';
@import "bootstrap-adjustments";

@import '~vue2-daterange-picker/dist/vue2-daterange-picker.css';
$vs-component-line-height: 1.1 !default;
@import '~vue-select/src/scss/vue-select.scss';
@import '~easymde/dist/easymde.min.css';

@import "components/main-header-navigation";
@import "components/url-select-state";