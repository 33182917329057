*:last-child {
  margin-bottom: 0;
}

.avatar {
  height: auto !important;
  max-width: 2em !important;
}

del {
  display: none;
  background-color: tomato !important;
}

ins {
  background-color: yellow !important;
  text-decoration: unset !important
}

// --- Anpassungen der Mauszeiger in Vue-Select -------------------------------------

.vs--searchable .vs__dropdown-toggle {
  cursor: default !important;

  .vs__search, .vs__selected {
    cursor: text;
  }
  
  .vs__actions {
    cursor: pointer;
  }
}
