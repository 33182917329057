body {
  //font-family: 'Roboto-condensed', sans-serif !important;
  font-size: 11pt;
}

.list-group-item .btn-sm {
  padding: 0.1rem 0.25rem 0.1rem 0.25rem ;
  font-size: 80%;
}

.list-group {
  .list-group-item {
    padding: 0.4rem;
  }
}

td .btn-sm {
  padding: 0.1rem 0.25rem 0.1rem 0.25rem ;
  font-size: 80%;
}
.card {
  margin-bottom: 1em;
}

// the color adjustment isn't honored inside fomr-control without this
.text-muted .form-control {
  color: #6c757d !important;
}

// Styling validation results
.is-invalid .vs__dropdown-toggle {
  border-color: #ff4136;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff4136' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff4136' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-valid .vs__dropdown-toggle {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

table.table {
  margin-bottom: 0;
}